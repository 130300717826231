import { useCallback } from 'react'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'
import { useNotifications } from '@trileuco/triskel-react-ui/components/ui/notifications'
import { useErrorNotification } from 'modules/bookables/notifications'

const useGenerateInvoice = (booking) => {
  const { notifyError } = useErrorNotification()
  const {
    notify,
    statusTypes: { LOADING, SUCCESS }
  } = useNotifications()

  const { msg } = useIntl({
    scope: 'balaena.api.useGenerateInvoice',
    ignoreGlobalScope: true
  })

  const notifyGenerateInvoice = useCallback(
    (notification) => {
      notify({
        id: `BOOKING_${booking.id}_GENERATE_INVOICE`,
        title: msg({ id: notification.status }),
        ...notification
      })
    },
    [msg, notify, booking]
  )

  const generateInvoice = useCallback(
    async (params) => {
      notifyGenerateInvoice({ status: LOADING })
      try {
        const response = await fetch(
          'https://cloud.yepcode.io/api/balaena/webhooks/satcomla-invoice-sync',
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Basic YmFsYWVuYTprSm9iNiY+USxwMjY='
            },
            body: JSON.stringify({ booking, params })
          }
        )

        const result = await response.json()
        if (result.error) {
          throw new Error(result.error.message)
        }
        notifyGenerateInvoice({ status: SUCCESS })
        return result
      } catch (error) {
        notifyError({
          id: `BOOKING_${booking.id}_GENERATE_INVOICE`,
          title: msg({ id: 'error' }),
          error: { data: { message: error.message } }
        })
      }
    },
    [notifyGenerateInvoice, LOADING, SUCCESS, notifyError, msg, booking]
  )

  return {
    generateInvoice,
    loading: false,
    error: false
  }
}

export default useGenerateInvoice
