import React from 'react'
import PropTypes from 'prop-types'
import { useIntl } from '@trileuco/triskel-react-ui/components/i18n'
import Modal from '@trileuco/triskel-react-ui/components/ui/Modal'
import Block from 'components/layout/Block'
import { useForm } from '@trileuco/triskel-react-ui/components/ui/Form'
import {
  Button,
  FieldSet,
  SelectField,
  TextField
} from '@trileuco/triskel-react-ui/components/ui'

const ToGenerateInvoiceModal = ({ handleGenerateInvoice, isOpen, close }) => {
  const { msg } = useIntl({
    scope: 'balaena.toGenerateInvoiceModal',
    ignoreGlobalScope: true
  })

  const {
    Form,
    values = {},
    ...instance
  } = useForm({
    tipoIdentificacion: '01',
    digitoVerificador: '00'
  })

  return (
    <Modal
      name="ToGenerateInvoiceModal"
      className="ToGenerateInvoiceModal"
      isOpen={isOpen}
      onClose={close}
      header={<h1>{msg({ id: 'modalTitle' })}</h1>}
      body={
        <Block className="ToGenerateInvoice" variant="clear">
          <Form>
            <FieldSet direction="column">
              <SelectField
                variant="outline"
                label={msg({ id: 'tipoIdentificacion' })}
                field="tipoIdentificacion"
                options={[
                  { label: 'Contribuyente', value: '01' },
                  { label: 'Consumidor final', value: '02' },
                  { label: 'Gobierno', value: '03' },
                  { label: 'Extranjero', value: '04' }
                ]}
              />
              {(values.tipoIdentificacion === '01' ||
                values.tipoIdentificacion === '03') && (
                <TextField
                  label={msg({ id: 'digitoVerificador' })}
                  field="digitoVerificador"
                  type="number"
                  maxLength={2}
                  placeholder="00"
                  disabled={
                    values.tipoIdentificacion !== '01' &&
                    values.tipoIdentificacion !== '03'
                  }
                />
              )}
              <Button
                disabled={!values.tipoIdentificacion}
                onClick={() =>
                  handleGenerateInvoice({
                    tipoIdentificacion: values.tipoIdentificacion,
                    digitoVerificador: values.digitoVerificador
                  }).then(() => {
                    close()
                    instance.reset()
                  })
                }
              >
                {msg({ id: 'modalButton' })}
              </Button>
            </FieldSet>
          </Form>
        </Block>
      }
    />
  )
}

ToGenerateInvoiceModal.propTypes = {
  booking: PropTypes.shape(),
  handleGenerateInvoice: PropTypes.func,
  isOpen: PropTypes.bool,
  close: PropTypes.func
}

ToGenerateInvoiceModal.displayName = 'ToGenerateInvoiceModal'

export default ToGenerateInvoiceModal
